<template>
    <v-container>
        <v-row class="text-center" justify="center" style="min-height: 80vh;">
            <v-col cols="12" md="10" lg="9" align-self="center" class="text-center" v-if="loading">
                <v-progress-circular indeterminate color="primary" size="70"></v-progress-circular>
            </v-col>
            <v-col cols="12" md="10" lg="9" align-self="center" class="text-center" v-if="!loading && valid && !success">
                <v-row justify="center">
                    <v-col cols="10">
                        <v-switch color="primary" v-model="allowAll" label="Für alle zukünftigen Sportangebote übernehmen"></v-switch>
                    </v-col>
                    <v-col cols="12">
                        <v-btn x-large rounded :loading="checking" color="primary" @click="verifyMail()">
                            E-Mail Adresse bestätigen
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="10" lg="9" align-self="center" class="text-center" v-if="!loading && !checking && success">
                <v-card color="primary" dark class="rounded-xl pa-5">
                    <font-awesome-icon class="mt-3" icon="fa-duotone fa-circle-check" size="9x" />
                    <h1 class="mt-5 mb-3">E-Mail wurde erfolgreich bestätigt</h1>
                </v-card>
                <v-btn class="mt-5" large rounded color="secondary" to="/">Zur Startseite</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { getFunctions, httpsCallable } from "firebase/functions"
import { doc, onSnapshot, setDoc, serverTimestamp } from 'firebase/firestore'
import md5 from 'md5'

export default {
    name: 'Account-Aktivierung',
    data() {
        return {
            valid: false,
            id: '',
            verify: '',
            ref: '',
            loading: true,
            checking: false,
            success: false,
            error: false,
            allowAll: false,
        }
    },
    computed: {
        ...mapGetters({
            user: 'user',
            vereine: 'vereine',
            sportarten: 'sportarten',
            sportangebote: 'sportangebote',
            db: 'db',
            app: 'app'
        }),
    },
    async mounted() {
        if (this.getQueryVariable('ref') && this.getQueryVariable('id') && this.getQueryVariable('code')) {

            this.id = this.getQueryVariable('id')
            this.verify = this.getQueryVariable('code')
            this.ref = this.getQueryVariable('ref')

            const unsub = onSnapshot(doc(this.db, "verify", md5(this.id+this.ref)), (doc) => {
                if(doc.exists()){
                    if(doc.data().status == 'approved' ){
                        this.success = true
                        this.loading = false
                        this.checking = false
                    }
                    else if(doc.data().status == 'declined'){
                        this.checking = false
                        this.error = false
                    }   
                    else {
                        this.valid = true
                        this.loading = false
                    }
                }
                else {
                    this.valid = true
                    this.loading = false
                }
            });

        }
    },
    methods: {
        async verifyMail(){
            this.checking = true

            const angebotRef = await setDoc(doc(this.db, 'verify', md5(this.id+this.ref)), {
                id: this.getQueryVariable('id'),
                verify: this.getQueryVariable('code'),
                ref: this.ref = this.getQueryVariable('ref'),
                all: this.allowAll,
                timestamp: serverTimestamp(),
                status: 'open'
            })
        },
        getQueryVariable(variable) {
            var query = window.location.search.substring(1)
            var vars = query.split('&')
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=')
                if (pair[0] == variable) {
                return pair[1]
                }
            }
            return false
        },
    }
}
</script>